import * as React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import {
  AnimationQuestion,
  ImageQuestion,
  MultiselectQuestion,
  SelectQuestion,
  SpecialSelectQuestion,
  TextQuestion,
} from "../../components/PageSegments/onboarding/";
import { AppButton } from "../../components/ui";
import {
  ContentCategory,
  DataT,
  QUESTION_TYPES,
  onboardingQuestions,
  thumbsData,
  globalThumbQuestions,
  attributionQuestion,
} from "../../content/static/onboardingQuestions";
import { useCounter } from "../../hooks/useCounter";
import { BasicLayout } from "../../layouts/BasicLayout";
import {
  onboarding_questions_final_report,
  onboarding_questions_start,
} from "../../services/mixpanel/mixpanel";
import { preloadImage, storeUTMCookies, storeUTMDataToLocalStorage } from "../../util/misc";
import theme from "../../util/theme";
import { mobileSize } from "../../util/variables";
import { useOnboardingContext } from "../../components/PageSegments/onboarding/OnboardingContext";
import { navigate } from "gatsby";
import { fbq } from "../../services/pixel";
import {
  web_acq_question_view,
  web_acq_submit_answer,
} from "../../services/mixpanel/acquisition-events";
import ThumbQuestion from "../../components/PageSegments/onboarding/questions/ThumbQuestion";
import { REMOTE_CONFIG_STRING_KEYS } from "../../services/firebase-remote-config";
import useRemoteConfig from "../../hooks/useRemoteConfig";
import { EMBEDDABLES_ONBOARDING_FLOW_ID } from "../../layouts/scripts";

const prepareOnboardingQuestions = (includeAttribution: boolean) => {
  const initialQuestions = [...onboardingQuestions];
  if (includeAttribution) {
    initialQuestions.splice(3, 0, attributionQuestion);
  }

  return [...initialQuestions, {}, {}, {}, {}];
};

export default ({ location }) => {
  const { counter, increment } = useCounter();
  const { onboardingAnswers, saveOnboardingDataToLocalStorage } = useOnboardingContext();

  const { search, state } = location;
  const onboardingQuestions = prepareOnboardingQuestions(state?.fromHomepage);
  const [questionSet, setQuestionSet] = React.useState(onboardingQuestions);
  const [questionData, setQuestionData] = React.useState(onboardingQuestions[0]);
  const [ABQuestionsAdded, setABQuestionsAdded] = React.useState(false);
  const params = new URLSearchParams(search);
  const flowParam = params.get("flow");
  const useEmbeddables = params.get("useEmbeddables");
  const [embeddablesTest, useDefault, configLoading] = useRemoteConfig(
    REMOTE_CONFIG_STRING_KEYS.embeddablesTest,
  );

  const runEmbeddablesScript = () => {
    if (useEmbeddables === "true") {
      return true;
    }

    if (useEmbeddables === "false") {
      return false;
    }

    return embeddablesTest === "embeddables";
  };

  useEffect(() => {
    if (flowParam) {
      localStorage.setItem("flow", flowParam);
    }

    if (search) {
      storeUTMCookies(search);
    }
  }, [search, flowParam]);

  const shouldDisableButton = () => {
    if (
      questionData.type !== QUESTION_TYPES.MultiSelect &&
      questionData.type !== QUESTION_TYPES.Select &&
      questionData.type !== QUESTION_TYPES.SpecialSelect
    ) {
      return false;
    }

    const answerKey = Object.keys(onboardingAnswers).find(key => key === questionData.key);
    const answerAdded = answerKey ? !!onboardingAnswers[answerKey] : false;

    return !answerAdded;
  };

  const nextQuestion = () => {
    let answerChoice;
    if (questionData.hasOwnProperty("key")) {
      answerChoice = onboardingAnswers[questionData.key];
    }

    web_acq_submit_answer(questionData.id, answerChoice);

    if (counter + 1 >= questionSet.length) {
      saveOnboardingDataToLocalStorage();
      if (!localStorage.getItem("utmData")) {
        storeUTMDataToLocalStorage();
      }

      onboarding_questions_final_report();
      fbq("trackCustom", "View_trial_prompt");

      navigate(
        flowParam === "polywise-ff"
          ? "/subscription/create-account/polywise-ff"
          : "/special-offer/join",
      );
      return;
    }

    setQuestionData(questionSet[counter + 1]);
    increment();
  };

  const renderQuestion = React.useCallback(() => {
    switch (questionData.type) {
      case QUESTION_TYPES.Animation:
        return (
          <AnimationQuestion
            key={questionData.id}
            title={questionData.title}
            asset={questionData.asset}
            hardcode={questionData.hardcode}
          />
        );
      case QUESTION_TYPES.Image:
        return (
          <ImageQuestion
            key={questionData.id}
            src={questionData.asset}
            alt={questionData.asset}
            title={questionData.title}
          />
        );
      case QUESTION_TYPES.MultiSelect:
        return (
          <MultiselectQuestion
            key={questionData.id}
            id={questionData.id}
            title={questionData.title}
            subtitle={questionData.subtitle}
            options={questionData.options}
            answer_key={questionData.key}
          />
        );
      case QUESTION_TYPES.Select:
        return (
          <SelectQuestion
            key={questionData.id}
            id={questionData.id}
            title={questionData.title}
            subtitle={questionData.subtitle}
            options={questionData.options}
            answer_key={questionData.key}
          />
        );
      case QUESTION_TYPES.SpecialSelect:
        return (
          <SpecialSelectQuestion
            key={questionData.id}
            id={questionData.id}
            answer_key={questionData.key}
          />
        );
      case QUESTION_TYPES.Text:
        return <TextQuestion key={questionData.id} title={questionData.title} />;
      case QUESTION_TYPES.Thumb:
        return (
          <ThumbQuestion
            data={questionData}
            nextQuestion={nextQuestion}
            questionNumber={counter - (onboardingQuestions.length - 5)}
          />
        );
      default:
        return <div>question not recognized</div>;
    }
  }, [questionData]);

  useEffect(() => {
    function preloadFutureImage(data: DataT) {
      switch (data.type) {
        case QUESTION_TYPES.Animation:
        case QUESTION_TYPES.Image:
        case QUESTION_TYPES.Thumb:
          preloadImage(data.asset);
          break;
        case QUESTION_TYPES.MultiSelect || QUESTION_TYPES.Select:
          data.options.forEach(opt => {
            if (opt.image) {
              preloadImage(opt.image);
            }
          });
          break;
        default:
          break;
      }
    }

    if (counter + 1 < questionSet.length) {
      preloadFutureImage(questionSet[counter + 1]);
    }

    if (counter + 2 < questionSet.length) {
      preloadFutureImage(questionSet[counter + 2]);
    }
  }, [counter]);

  useEffect(() => {
    if (!questionData) return;
    if (questionData.id === "quoteRevamped_1") {
      // hardcoded arbitrary question to send pixel event
      fbq("track", "ViewContent");

      // AB TEST THUMBS - START
      // add 3 additional questions based on topics + 1 static
      if (!ABQuestionsAdded && !!onboardingAnswers["whichTopicsDoYouWant_3"]) {
        // always guarantee 4 topics
        const inputtedTopics = onboardingAnswers["whichTopicsDoYouWant_3"].split(", ");
        const topics = [...inputtedTopics, ...inputtedTopics, ...inputtedTopics].slice(0, 3);

        const categoryPriority = {
          [ContentCategory.Philosophy]: 0,
          [ContentCategory.Psychology]: 1,
          [ContentCategory.ScienceAndTech]: 2,
          [ContentCategory.Economics]: 3,
          [ContentCategory.Productivity]: 4,
          [ContentCategory.Leadership]: 5,
          [ContentCategory.SelfHelp]: 6,
          [ContentCategory.History]: 7,
          [ContentCategory.HealthAndWellness]: 8,
          [ContentCategory.Business]: 9,
        };

        topics.sort((a, b) => {
          const indexA = categoryPriority[a as ContentCategory];
          const indexB = categoryPriority[b as ContentCategory];
          return indexA - indexB;
        });

        console.log(topics);

        const additionalQuestions = topics.reduce((curAdditionalQuestions, topic) => {
          const questionsData = thumbsData[topic as ContentCategory];
          const questionToAdd = questionsData.find(q => {
            return !curAdditionalQuestions.some(aq => aq.id === q.id);
          });
          return questionToAdd
            ? curAdditionalQuestions.concat(questionToAdd)
            : curAdditionalQuestions;
        }, globalThumbQuestions);

        setQuestionSet([...questionSet.slice(0, -4), ...additionalQuestions]);
        setABQuestionsAdded(true);
      }
      // AB TEST THUMBS - END
    }
  }, [questionData, ABQuestionsAdded, onboardingAnswers, questionSet]);

  useEffect(() => {
    if (!questionData) return;

    if (useEmbeddables === "false" || embeddablesTest === "control") {
      onboarding_questions_start();
      web_acq_question_view(questionData.id);
    }
  }, [questionData, useEmbeddables, embeddablesTest]);

  if ((embeddablesTest === null || configLoading) && !useDefault) {
    return null;
  }

  return (
    <BasicLayout
      themeColor={theme.WHITE_COLOR}
      runEmbeddedablesScript={runEmbeddablesScript()}
      embeddedablesFlowId={EMBEDDABLES_ONBOARDING_FLOW_ID}
    >
      {!runEmbeddablesScript() && (
        <Wrapper
          background={
            questionData.hasOwnProperty("background") ? questionData.background : theme.WHITE_COLOR
          }
        >
          <Content>
            <ProgressBar childrenLength={questionSet.length} currentIndex={counter} />
            {renderQuestion()}
            <Spacer />
            {counter < onboardingQuestions.length && questionData.type !== QUESTION_TYPES.Thumb && (
              <ContinueButton
                type="button"
                background={questionData.hasOwnProperty("background") ? "#292032" : undefined}
                disabled={shouldDisableButton()}
                onClick={nextQuestion}
              >
                Continue
              </ContinueButton>
            )}
          </Content>
        </Wrapper>
      )}
    </BasicLayout>
  );
};

const Wrapper = styled.div<{ background: string }>`
  width: 100%;
  height: 100dvh;
  background-color: ${props => props.background};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100dvh;
  max-width: 800px;
  box-sizing: border-box;
  padding: 48px;
  margin: 0 auto;
  @media ${mobileSize} {
    padding: 36px;
    /* justify-content: space-between; */
  }
`;

interface FillerProps {
  childrenLength: number;
  currentIndex: number;
}

const ProgressBar = styled.div<FillerProps>`
  position: relative;
  height: 8px;
  min-height: 8px;
  width: 334px;
  margin: 0px auto;
  margin-bottom: 48px;
  border-radius: 50px;
  background-color: #edeeef;
  z-index: 100;

  @media ${mobileSize} {
    margin: 0 auto;
    width: 100%;
    max-width: 240px;
    margin-bottom: 40px;
  }

  ::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    background-color: ${theme.GREEN_ACCENT};
    height: inherit;
    border-radius: inherit;
    transition: width 0.2s ease-out;
    width: ${props => `${(props.currentIndex / (props.childrenLength - 1)) * 100}%`};
  }
`;

const ContinueButton = styled(AppButton as any)`
  margin-top: auto;
  height: 48px;
  @media ${mobileSize} {
    /* margin-top: 32px; */
    width: 100%;
  }
`;

const Spacer = styled.div`
  padding: 12px; // continue_button_height / 2
  @media ${mobileSize} {
    /* padding: 48px; */
  }
`;
